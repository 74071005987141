<template>
      <Navbar v-show="!loading" @goto="goto" id="top"
      logoImgDark="LogoDark.png"
      logoImgWhite="LogoWhite.png"
      :navLinks="[
        {
          name: 'Story',
          link: 'storyRef',
          dropdown: false,
        },
        { name: 'Roadmap', link: 'roadmapRef', dropdown: false },
        {
          name: 'Team',
          link: 'teamRef',
          dropdown: false,
        },
        /**{
          name: 'Mint',
          link: 'mintRef',
          dropdown: false,
        },*/
      ]"
    />
  <Wallpaper v-show="!loading"/>
  <Loading v-if="loading"/>
  <Story id="storyRef" v-show="!loading"/>
  <ImageGallery v-if="!phone" v-show="!loading"/>
  <TimeLine id="roadmapRef" v-show="!loading"/>
  <!--<MintMain id="mintRef"/>-->
  <Team id="teamRef" v-show="!loading"/>
  <FrequentlyAskedQuestions v-show="!loading"/>
  <Footer @goto="goto" v-show="!loading"/>
  <FabButton v-show="!loading"/>
  <Mint_NFT_Button v-show="!loading"/>

</template>

<script>
// @ is an alias to /src

import Loading from '@/components/Loading.vue'

import { defineAsyncComponent } from 'vue' 
const Navbar = defineAsyncComponent(() => 
  import('@/components/Navbar.vue') 
) 
import Wallpaper from '@/components/Wallpaper.vue'
/**const Wallpaper = defineAsyncComponent(() => 
  import('@/components/Wallpaper.vue') 
) **/
//import Story from '@/components/Story.vue'
const Story = defineAsyncComponent(() => 
  import('@/components/Story.vue') 
) 
//import ImageGallery from '@/components/ImageGallery.vue'
const ImageGallery = defineAsyncComponent(() => 
  import('@/components/ImageGallery.vue') 
) 
//import TimeLine from '@/components/Timeline.vue'
const TimeLine = defineAsyncComponent(() => 
  import('@/components/Timeline.vue') 
) 
//import Team from '@/components/Team/Team.vue'
const Team = defineAsyncComponent(() => 
  import('@/components/Team/Team.vue') 
) 
//import FrequentlyAskedQuestions from '@/components/FAQ/FrequentlyAskedQuestions.vue'
const FrequentlyAskedQuestions = defineAsyncComponent(() => 
  import('@/components/FAQ/FrequentlyAskedQuestions.vue') 
) 
//import MintMain from '@/components/Mint/MintMain.vue'
//import Footer from '@/components/Footer.vue'
const Footer = defineAsyncComponent(() => 
  import('@/components/Footer.vue') 
) 
//import FabButton from '@/components/FabButton.vue'
const FabButton = defineAsyncComponent(() => 
  import('@/components/FabButton.vue') 
) 

import Mint_NFT_Button from '@/components/MintNFTButton.vue'




export default {
  name: 'Home',
  data() {
    return {
      loading: true,
      phone: false,
    }
  },
  components: {
    Loading,
    Navbar,
    TimeLine,
    Footer,
    Wallpaper,
    Story,
    Team,
    //MintMain,
    FrequentlyAskedQuestions,
    ImageGallery,
    FabButton,
    Mint_NFT_Button
  },
  methods: {
     goto(refName) {
      var element = document.getElementById(refName);
      var top = element.offsetTop;
      window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
    },
    setPhone(){
      if (window.innerWidth <= 550) {
        this.phone = true
      }else{
        this.phone = false
      }
    },
  },
  mounted() {
    this.setPhone()
    setTimeout(() => this.loading = false, 1500);
    window.addEventListener("resize", this.setPhone);
  }
}
</script>

<style scoped>

.placeHolder{
  width: 100%;
  height: 63px;
}
.padding-page-content{
  padding-left: 20px;
  padding-right: 20px;
}

</style>
