<template>
  <div>
    <div class="contactButtonWrapper">
      <a class="contactButton" href="https://opensea.io/collection/nft-world-news-futurebots" data-v-76dc4009="" style="border: 0px;">Mint NFT</a>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Mint_NFT_Button',
  props: [],
  components: {
    
  },
  methods: {
  },
  mounted() {}
}
</script>

<style scoped>

.contactButton{
  padding: 10px 30px;
  border-radius: 50px;
  color: #fff;
  text-decoration: none;
  background-color: rgb(56, 217, 150);
  border: 0px;
  font-size: 20px;
}

.contactButtonWrapper{
  position:fixed;
  padding: 1rem;
  bottom: 20px;
  cursor:pointer;
  right: 0px;
}


</style>
