<template>
    <div class="wrapper">
        <div class="video-wrap" ref="videoArea">
            <!--<video class="animate-opacity" autoplay="true" playsinline="true" muted="muted" loop="true" id="vid" v-bind:style="{ 'opacity': wallpaperOpacity}">
                <source src="blob:https://www.youtube.com/2eb72078-0833-4357-ac24-a652be403c06" type="video/mp4">
            </video>-->
        </div>
        <div class="video-wrap" ref="videoArea" v-bind:style="{ 'opacity': wallpaperOpacity, backgroundColor: 'black'}">
          <EarthWallpaper v-if="!phone"/>
          <img v-if="phone" class="staticWallpaper imageZoomInEffect" src="../assets/wallpaper/video.gif">
        </div>
        <div class="overlay"></div>
        <div class="typed-text-area">
          <div class="logoBigWrapper">
            <img class="logoBig" src="../assets/logo/LogoWhite.png">
          </div>
          <div style="margin-left: 50px; margin-right: 50px;">
            <span class="typed-text">{{typeValue}}</span>
            <span class="cursor" :class="{'typing': typeStatus}">&nbsp;</span>
          </div>
        </div>
        <div v-if="displayScroll" class="icon-scroll"></div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import EarthWallpaper from '@/components/EarthWallpaper.vue'
export default {
  name: 'Wallpaper',
  components: {
    EarthWallpaper
  },
  data() {
        return {
            displayScroll: false,
            typeValue: '',
            typeStatus: false,
            typeArray: [],
            typingSpeed: 100,
            deletingSpeed: 5,
            newTextDelay: 1500,
            typeArrayIndex: 0,
            charIndex: 0,
            inDelete: false,
            intervalObject: null,
            wallpaperOpacity: 0.9999,
            phone: true
        }
  },
  props: [],
  methods: {
    setImageOrCanvas(){
      if (window.innerWidth <= 550) {
        this.phone = true
      }else{
        this.phone = false
      }
    },
    setDisplayScollIcon() {
        if (window.scrollY >= 80 || window.innerWidth <= 1000) {
            this.displayScroll = false;
        } else {
            this.displayScroll = true;
        }
     },
     startWriteInterval() {
      this.intervalObject = window.setInterval(() => {
        this.typeText()
      }, this.typingSpeed)
     },
     startDeletingInterval(){
      this.intervalObject = window.setInterval(() => {
        this.deleteText()
      }, this.deletingSpeed)
     },
     typeText(){
      if (!this.inDelete && this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) {
          this.typestatus = true;          
        }

        this.typeValue += this.typeArray[this.typeArrayIndex][this.charIndex];
        this.charIndex += 1;

      } else {
        this.typeStatus = false;

          if(this.intervalObject) {
            clearInterval(this.intervalObject);
          }
          setTimeout(() => {  this.startDeletingInterval() }, this.newTextDelay);
      }
     },
      deleteText(){
        this.inDelete = true;
        if (this.charIndex > 0) {
          if (!this.typeStatus) {
            this.typestatus = true;
          }

          this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
          this.charIndex -= 1
          
        } else {
          this.typeStatus = false;
          this.typeArrayIndex += 1
          this.inDelete = false;

          if (this.typeArrayIndex >= this.typeArray.length) {
            this.typeArrayIndex = 0
          }
          if(this.intervalObject) {
            clearInterval(this.intervalObject);
          }
          setTimeout(() => {  this.startWriteInterval() }, this.newTextDelay);
        }
      },
    setWallpaperOpacity(){
      let hiddenPercentage = window.scrollY / this.$refs.videoArea.clientHeight;
      this.setHiddenPercentage(window.scrollY / this.$refs.videoArea.clientHeight)
      if (hiddenPercentage <= 0.1999) {
        this.wallpaperOpacity = 0.99;
      } else if (hiddenPercentage >= 0.9) {
        this.wallpaperOpacity = 0;
      } else if (hiddenPercentage >= 0.8) {
        this.wallpaperOpacity = 0;
      } else if (hiddenPercentage >= 0.7) {
        this.wallpaperOpacity = 0.3;
      } else if (hiddenPercentage >= 0.6) {
        this.wallpaperOpacity = 0.4;
      } else if (hiddenPercentage >= 0.5) {
        this.wallpaperOpacity = 0.5;
      } else if (hiddenPercentage >= 0.4) {
        this.wallpaperOpacity = 0.6;
      } else if (hiddenPercentage >= 0.3) {
        this.wallpaperOpacity = 0.7;
      } else if (hiddenPercentage >= 0.2) {
        this.wallpaperOpacity = 0.85;
      }
    },
    ...mapMutations([
      'setHiddenPercentage'
    ])
  },
  created(){
    
  },
  mounted() {
    this.setImageOrCanvas()
    window.addEventListener("scroll", this.setWallpaperOpacity);
    /**Typer Content */
    this.typeArray.push('Show the world that you care about the future by owning a FUTUREBOT!')
    //this.typeArray.push('Be an early adopter for the first data-driven NFT World News Dashboard!')
    this.typeArray.push('')

    this.startWriteInterval();
    //document.getElementById('vid').play()
    window.addEventListener("scroll", this.setDisplayScollIcon);
    window.addEventListener("load", this.setDisplayScollIcon);
    window.addEventListener("resize", this.setDisplayScollIcon);

    //img or canvas
    window.addEventListener("resize", this.setImageOrCanvas);
  },
  computed: {

  },
}
</script>

<style scoped>

.staticWallpaper{
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageZoomInEffect {
  animation: zoomIn 2s;
  -moz-animation: zoomIn 2s; /* Firefox */
  -webkit-animation: zoomIn 2s; /* Safari and Chrome */
  -o-animation: zoomIn 2s; /* Opera */
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

@keyframes zoomIn {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}
@-moz-keyframes zoomIn {
  /* Firefox */
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}
@-webkit-keyframes zoomIn {
  /* Safari and Chrome */
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}
@-o-keyframes zoomIn {
  /* Opera */
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
}

.logoBig{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.logoBigWrapper{
  width: 100%;
  margin-bottom: 70px;
  display: block;
}

.typed-text-area {
	color: white;
	z-index: 2;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.8rem;
}

.cursor{
  display: inline-block;
  margin-left: 3px;
  width: 2px;
  background-color: white;
  animation: cursorBlink 0.9s infinite;
}

.animate-opacity{
    animation:opac 0.8s
  }

@keyframes opac{
  from{
    opacity:0
    } to{
      opacity:1
    }
}

@keyframes cursorBlink{
  49% { background-color: white; }
  50% { background-color: transparent;}
  99% { background-color: transparent;}
}

.typing{
  animation: none;
}

video{
    object-fit: cover;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 1px;
}
.wrapper {
	text-align: center;
	height: 100vh;
	display: flex;
	align-items: center;
	color: #FFF;
  overflow: hidden;

}
.video-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.fade{
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
.video-wrap video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}
/* Tablet */
@media screen and (max-width: 1000px) {
    .logoBigWrapper{
        display: none;
    }
    .btn{
        display: none;
    }
    .wrapper {
        height: 50vh;
        display: flex;
        align-items: center;
        color: #FFF;
    }
    .video-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        overflow: hidden;
    }
    .video-wrap video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }
}
/* Mobile */
@media screen and (max-width: 768px) {
    .logoBigWrapper{
        display: none;
    }
    .typed-text-area {
      font-size: 1.5rem;
    }
    .btn{
        display: none;
    }
    .wrapper {
        height: 50vh;
        display: flex;
        align-items: center;
        color: #FFF;
    }
    .video-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        overflow: hidden;
    }
    .video-wrap video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }
}

@media (min-width: 1000px) {
  .icon-scroll,
  .icon-scroll:before {
    position: absolute;
    left: 50%;
  }

  .icon-scroll {
    width: 40px;
    height: 70px;
    margin-left: -20px;
    bottom: 20px;
    margin-top: -35px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 25px;
  }

  .icon-scroll:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }

  @keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(46px);
    }
  }
  .zoom-in{
    animation: zoom-in 2s 
  }
  @keyframes zoom-in {
    0% {
    transform: scale(1);
    }
    100% {
    transform: scale(1.03);
    }
  }
}

</style>
